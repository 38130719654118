<template>
  <div>
    <BModal
      id="modal-recalculate-price-VN1A"
      :title="$t('reservation.recalculatePriceFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      centered
      no-close-on-backdrop
      size="lg"
      @show="handleShowModal"
    >
      <!-- ANCHOR Thông tin hành trình -->
      <b-card body-class="p-1">
        <div class="text-body fw-600 mb-25">
          {{ $t('reservation.recalculatePrice.itinerariesInformation') }}:
        </div>

        <div
          v-for="(itinerary, indexItinerary) in getBookingData.itineraries"
          :key="indexItinerary"
          class="d-flex flex-column py-75"
        >
          <div
            v-for="(segment, indexSegment) in itinerary"
            :key="indexSegment"
          >
            <div class="mr-50 fw-700">
              {{ convertShortTrip(segment) }}
            </div>
          </div>
        </div>

        <!-- ANCHOR - DOB checkbox -->
        <b-form-checkbox
          v-model="isDobRecalculate"
          name="dob-checkbox"
          class="my-1"
          :disabled="isBargainFinder"
        >
          <div class="text-body fw-600 font-medium-1">
            {{ $t('reservation.recalculatePrice.withDOB') }}
          </div>
        </b-form-checkbox>

        <!-- ANCHOR - Thông tin hành khách -->
        <div class="text-body fw-600 mb-25">
          {{ $t('reservation.recalculatePrice.paxInformation') }}:
        </div>

        <ol class="mb-50">
          <b-row
            v-for="(pax, indexPax) in getBookingData.paxLists"
            :key="indexPax"
            class="fw-700 my-50"
          >
            <b-col
              cols="12"
              lg="6"
              class="d-flex-center justify-content-start"
              style="padding: 0.55rem 0"
            >
              {{ pax.paxId }}. {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
            </b-col>
            <b-col
              v-if="isDobRecalculate"
              cols="12"
              lg="6"
            >
              <SelectBirthDate :dob.sync="paxInfoForDOB[indexPax].birthday" />
            </b-col>
          </b-row>
        </ol>

        <div class="text-heading-4 mb-25">
          Giá vé: <span class="fw-700 text-airline text-heading-2">{{ formatCurrency(hideFee ? getBookingData.totalAmountToBePaid : getBookingData.fareInfo.total) }}</span>
        </div>
        <BAlert
          v-if="errorMessage"
          show
          variant="danger"
          class="fw-700 py-75 mt-1 text-center px-75"
        >
          {{ errorMessage }}
        </BAlert>

        <BAlert
          show
          variant="warning"
          class="fw-700 py-75 mt-1 text-center px-75"
        >
          (*) {{ $t('reservation.recalculatePrice[\'The system will automatically recalculate the price for all passengers on the booking\']') }} !
        </BAlert>
      </b-card>

      <!-- ANCHOR - Retain and BargainFinder -->
      <b-card
        v-if="canPriceBargainFinder"
        body-class="py-1"
      >
        <div class="d-flex-center">
          <b-form-checkbox
            v-model="isBargainFinder"
            name="retain-checkbox"
            :disabled="isDobRecalculate"
          >
            <div class="text-body fw-600 font-medium-1">
              {{ $t('reservation.bargainFinder') }}
            </div>
          </b-form-checkbox>
        </div>
      </b-card>
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient"
          pill
          @click="submitRecalculatePrice"
        >
          <span class="text-uppercase">{{ $t('reservation.recalculatePrice.title') }}</span>
        </b-button>
      </template>
    </BModal>

    <!-- ======================================== MODAL SHOW PRICE =================================== -->
    <BModal
      id="modal-recalculate-show-price-VN1A"
      :title="$t('reservation.showCalculatePrice.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      hide-header-close
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="rounded-pill px-2"
          @click="handleClose(close)"
        >
          {{ $t('reservation.close') }}
        </b-button>
        <!-- v-if="newPrice && !isEmpty(newPrice.booking) && newPrice.isWarning" -->
        <b-button
          v-if="!isDobRecalculate"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient"
          pill
          @click="handleSavePrice"
        >
          <span class="">LƯU GIÁ MỚI</span>
        </b-button>
      </template>

      <!-- <IAmOverlay :loading="!newPrice"> -->
      <div
        v-if="newPrice"
        class="position-relative"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-secondary"
          class="position-absolute p-75 rounded-circle m-25"
          style="top: 0; right: 10px; z-index: 9999;"
          :disabled="disabledCopy"
          @click="handleCopyText"
        >
          <feather-icon
            v-b-tooltip.hover.window
            icon="CopyIcon"
            size="21"
            class="text-dark"
            :title="$t('copy')"
          />
        </b-button>

        <template v-if="!isEmpty(newPrice.booking)">
          <b-form-textarea
            :value="newPrice.booking.join('\n')"
            class="font-weight-bolder text-uppercase"
            rows="16"
            max-rows="40"
            style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif; height: 70vh; overflow: auto;"
          />
        </template>

        <BAlert
          :show="newPrice.isWarning"
          variant="warning"
          class="py-75 mt-1 text-center px-75"
        >
          <span class="fw-700 font-medium-2 text-danger">
            GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!!
          </span>
        </BAlert>
      </div>

      <div
        v-if="!isEmpty(newPriceItinerary)"
        class="position-relative"
      >
        <BTableLite
          v-if="!isEmpty(newPriceItinerary)"
          bordered
          responsive
          small
          hover
          striped
          body-class="p-0"
          class="mb-1 rounded"
          thead-class="text-center font-weight-bolder text-nowrap"
          tbody-class="text-center text-nowrap"
          :fields="['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount']"
          :items="newPriceItinerary"
        >
          <template
            v-for="(column, indexColumn) in ['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount']"
            #[`head(${column})`]="data"
          >
            <div :key="indexColumn">
              <span>{{ $t(`reservation.${data.column}`) }}</span>
            </div>
          </template>

          <template #cell(passengerType)="{ item }">
            {{ item.paxType }}
          </template>
          <template #cell(net)="{ item }">
            {{ formatCurrency(item.netFare) }}
          </template>
          <template #cell(tax)="{ item }">
            {{ formatCurrency(item.tax) }}
          </template>
          <template #cell(total)="{ item }">
            <span class="fw-600">{{ formatCurrency(item.totalPrice) }}</span>
          </template>
          <template #cell(quantity)="{ item }">
            {{ item.paxNum ? formatCurrency(item.paxNum) : 1 }}
          </template>
          <template #cell(totalAmount)="{ item }">
            <span class="fw-800">{{ item.paxNum ? formatCurrency(item.totalPrice * item.paxNum) : formatCurrency(item.totalPrice) }}</span>
          </template>
        </BTableLite>

        <p :class="`text-right text-body text-nowrap mr-md-2 ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
          Tổng tiền: <span :class="`text-airline fw-800 ${isMobileView ? 'font-medium-1' : 'font-medium-3'}`">{{ formatCurrency(newPriceItinerary.reduce((total, item) => total + (item.paxNum ? (item.totalPrice * item.paxNum) : item.totalPrice), 0)) }} VND</span>
        </p>

        <BAlert
          :show="isWarningItinerary"
          variant="warning"
          class="py-75 mt-1 text-center px-75"
        >
          <span :class="`fw-700 ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
            GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!!
          </span>
        </BAlert>
      </div>
      <!-- </IAmOverlay> -->
    </BModal>
  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BTableLite,
  VBTooltip,
  BButton,
  BCol,
  BRow,
} from 'bootstrap-vue'
import {
  ref, toRefs, computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { useClipboard } from '@vueuse/core'

import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
import store from '@/store'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BAlert,
    BModal,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BTableLite,
    BCard,
    BCol,
    BRow,
    // IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    SelectBirthDate: () => import('../components/SelectBirthDate.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root }) {
    const { toastSuccess, toastError } = useToast()
    const { copy } = useClipboard()
    const {
      recalculatePriceCommandAddDob,
      recalculatePriceItinerary,
    } = useReservationHandle()

    const getBookingData = toRefs(props).bookingData
    const isRetain = ref(false)
    const isBargainFinder = ref(false)
    const newPrice = ref([])
    const newPriceItinerary = ref(null)
    const isWarningItinerary = ref(false)

    const isDobRecalculate = ref(false)
    const paxInfoForDOB = ref()
    const errorMessage = ref('')

    function handleShowModal() {
      isBargainFinder.value = false
      isRetain.value = false
      isDobRecalculate.value = false
      newPrice.value = null
      newPriceItinerary.value = []
      isWarningItinerary.value = false

      errorMessage.value = ''
      paxInfoForDOB.value = getBookingData.value.paxLists.map(pax => ({
        firstName: pax.firstName,
        lastName: pax.lastName,
        title: pax.title,
        birthday: pax.birthday,
      }))
    }

    const disabledCopy = ref(false)

    function handleCopyText() {
      if (!newPrice.value || (newPrice.value && isEmpty(newPrice.value.booking))) return

      disabledCopy.value = true

      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      const textValue = newPrice.value.booking.join('\n')
      copy(textValue)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    const prePayload = ref()
    function recalculatePriceNormal() {
      prePayload.value = null
      newPrice.value = null
      newPriceItinerary.value = []
      isWarningItinerary.value = false

      const payload = {
        source: getBookingData.value.source,
        pnrNumber: getBookingData.value.bookingCode,
        retain: false,
        bargainFinder: isBargainFinder.value,
      }

      prePayload.value = cloneDeep(payload)

      root.$bvModal.show('modal-api-loading')

      recalculatePriceItinerary(payload)
        .then(res => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.recalculatePriceSuccess',
          })

          newPriceItinerary.value = res?.paxPrice
          isWarningItinerary.value = res?.isWarning

          root.$bvModal.show('modal-recalculate-show-price-VN1A')
        })
        .catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.recalculatePriceError',
          })
        })
        .finally(() => {
          root.$bvModal.hide('modal-api-loading')
        })
    }

    function recalculatePriceWithDOB() {
      prePayload.value = null
      newPrice.value = null
      newPriceItinerary.value = []
      isWarningItinerary.value = false

      if (paxInfoForDOB.value.some(pax => !pax.birthday)) {
        errorMessage.value = 'Vui lòng nhập đầy đủ thông tin ngày tháng năm sinh của tất cả hành khách'
        return
      }

      const payload = {
        source: getBookingData.value.source,
        pnrNumber: getBookingData.value.bookingCode,
        emailAddress: getBookingData.value.contact.paxInfo.email[0].address || '',
        phoneNumber: getBookingData.value.contact.paxInfo.phone[0].address || '',
        paxInfo: paxInfoForDOB.value,
      }

      prePayload.value = cloneDeep(payload)

      root.$bvModal.show('modal-api-loading')

      recalculatePriceCommandAddDob(payload)
        .then(res => {
          newPrice.value = res
          root.$bvModal.show('modal-recalculate-show-price-VN1A')
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.recalculatePriceSuccess',
          })
        })
        .catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.recalculatePriceError',
          })
        })
        .finally(() => {
          root.$bvModal.hide('modal-api-loading')
        })
    }

    function submitRecalculatePrice() {
      if (isDobRecalculate.value) {
        recalculatePriceWithDOB()
      } else {
        recalculatePriceNormal()
      }
    }

    // const showCalculatePriceLoading = ref(false)

    function handleSavePrice() {
      if (prePayload.value) {
        // showCalculatePriceLoading.value = true
        this.$bvModal.show('modal-api-loading')

        recalculatePriceItinerary({
          ...prePayload.value,
          retain: true,
        })
          .then(() => {
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.reservation.saveRecalculatePriceSuccess',
            })
          })
          .catch(() => {
            toastError({
              title: 'messagesList.error',
              content: 'messagesList.reservation.saveRecalculatePriceError',
            })
          })
          .finally(() => {
            // showCalculatePriceLoading.value = false
            this.$bvModal.hide('modal-api-loading')

            this.$bvModal.hide('modal-recalculate-price-VN1A')
            this.$bvModal.hide('modal-recalculate-show-price-VN1A')
          })
      }
    }

    // F2, F3 tự bật isPriceBargainFinder trong employeeConfig mới được chọn tính giá rẻ nhất
    const canPriceBargainFinder = computed(() => {
      const employeeConfig = store.getters['userStore/getEmployeeConfig']
      const isRoleF1 = store.getters['userStore/getRoleMama']
      const { isPriceBargainFinder } = employeeConfig
      return !!(!isRoleF1 && isPriceBargainFinder) || isRoleF1
    })
    const hideFee = computed(() => store.getters['userStore/getHideFee'])

    function handleClose(closeFunc) {
      if (!isDobRecalculate.value) closeFunc()
      else {
        this.$bvModal.hide('modal-recalculate-price-VN1A')
        this.$bvModal.hide('modal-recalculate-show-price-VN1A')
      }
    }
    return {
      hideFee,
      getBookingData,
      isRetain,
      isEmpty,
      isBargainFinder,
      formatCurrency,
      submitRecalculatePrice,
      convertShortTrip,
      convertISODateTime,
      upperCaseFormatter,
      handleShowModal,

      // for show price
      newPrice,
      newPriceItinerary,
      isWarningItinerary,
      prePayload,
      canPriceBargainFinder,
      // showCalculatePriceLoading,
      handleSavePrice,

      disabledCopy,
      handleCopyText,
      isDobRecalculate,
      paxInfoForDOB,
      errorMessage,
      handleClose,
    }
  },
}
</script>
